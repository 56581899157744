<template>
  <PageWrapper>
    <PagePanel>
      <div class="personal_cont">
        <p class="title">{{ $t('common.field.infoDetails') }}</p>
        <el-row :gutter="16">
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="name"></svg-icon>
                <span>{{ $t('common.keys.NAME') }}</span>
              </div>
              <div class="bottom" v-html="clientProfileData.name" v-if="clientProfileData.name"></div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="country"></svg-icon>
                <span>{{ $t('common.field.country') }}</span>
              </div>
              <div class="bottom" v-html="clientProfileData.country" v-if="clientProfileData.country"></div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="accType"></svg-icon>
                <span>{{ $t('common.keys.ACCTYPE') }}</span>
              </div>
              <div class="bottom" v-html="accountType[clientProfileData.accountType]"
                v-if="clientProfileData.accountType">
              </div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="address"></svg-icon>
                <span>{{ $t('common.field.address') }}</span>
              </div>
              <div class="bottom" v-html="clientProfileData.streetAddress" v-if="clientProfileData.streetAddress"></div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="email"></svg-icon>
                <span>{{ $t('common.field.emailAdd') }}</span>
              </div>
              <div class="bottom" v-html="clientProfileData.emailAddress" v-if="clientProfileData.emailAddress"></div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="invest"></svg-icon>
                <span>{{ $t('common.field.save') }}</span>
              </div>
              <div class="bottom" v-html="clientProfileData.savingsInvestment" v-if="clientProfileData.savingsInvestment">
              </div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="phone"></svg-icon>
                <span>{{ $t('common.field.phone') }}</span>
              </div>
              <div class="bottom" v-html="clientProfileData.phone" v-if="clientProfileData.phone"></div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="income"></svg-icon>
                <span>{{ $t('common.field.annIncome') }}</span>
              </div>
              <div class="bottom" v-html="clientProfileData.averageIncome" v-if="clientProfileData.averageIncome"></div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="cont">
              <div class="top">
                <svg-icon iconClass="earth"></svg-icon>
                <span>{{ $t('common.field.nat') }}</span>
              </div>
              <div class="bottom" v-html="clientProfileData.nationality" v-if="clientProfileData.nationality"></div>
              <div class="bottom no_bind" v-else>{{ $t('common.field.noBind') }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </PagePanel>
  </PageWrapper>
</template>
<script>
import { apiInfo } from '@/resource';

export default {
  data() {
    return {
      clientProfileData: {
        accountType: '',
        name: '',
        emailAddress: '',
        phone: '',
        dateBirth: '',
        nationality: '',
        country: '',
        streetAddress: '',
        suburb: '',
        state: '',
        postcode: '',
        employmentStatus: '',
        averageIncome: '',
        savingsInvestment: '',
        sourceOfFunds: ''
      },
      accountType: {
        1: 'Demo Account',
        2: 'Personal Account',
        3: 'Master Joint Account',
        4: 'Vice Joint Account',
        5: 'IB Individual Account',
        6: 'Internal Account',
        7: 'Experience Account',
        8: 'Leads Account',
        9: 'Repetitive Leads Account',
        10: 'Imported Leads Account',
        11: 'Irregular Leads Account',
        13: 'Company Account',
        14: 'SMSF Account',
        15: 'Vice Leads Account',
        16: 'IB Company Account',
        17: 'Migration Leads Account',
        18: 'Migration Demo Account',
        19: 'Conflict Account'
      }
    };
  },
  mounted() {
    apiInfo().then(resp => {
      if (resp.data.code == 0) this.clientProfileData = resp.data.data;
    });
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/settings.scss';
.personal_cont {
  .title {
    font-size: 16px;
    color: #09090D;
    line-height: 24px;
    font-weight: 700;
  }

  @include screen-mobile {
    .el-col:nth-child(1) {
      .cont {
        margin-top: 24px;
      }
    }
  }

  .cont {
    padding: 16px;
    background: $background-color-base;
    border-radius: 8px;
    margin-top: 16px;

    .top {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .svg-icon {
        font-size: 24px;
        @include rtl-sass-prop(margin-right, margin-left, 8px);
      }

      span {
        font-size: 16px;
        color: $text-primary;
        line-height: 24px;
        font-weight: 700;
      }
    }

    .bottom {
      font-size: 14px;
      color: $text-secondary;
      line-height: 22px;
      @include rtl-sass-prop(margin-left, margin-right, 32px);

      &.no_bind {
        color: #ACACBF;
      }
    }

    &.tip_cont {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        margin-right: 4px;
      }
    }
  }
}
</style>
